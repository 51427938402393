import React, { useContext, useState } from 'react';
import { FiBookOpen, FiDollarSign, FiEdit2, FiFilePlus, FiFolder, FiMenu, FiSettings, FiTag, FiTrash2, FiZap, FiZapOff } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import ClientInt from '~/interfaces/Clients';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Interfaces
interface CompProps {
    folder     : string;
    edit       : Function;
    energies   : Function;
    prices     : Function;
    others     : Function;
    teACL      : Function;
    othersNotes: Function;
    icms       : Function;
    lostEnergy : Function;
    delete     : Function;
}

type Props = ClientInt & CompProps;

const Clients: React.FC<Props> = (props) => {
    const [folderStatus, setFolderStatus]     = useState<string>('');
    const [testStatus, setTestStatus]         = useState<string>('');
    const [testStatusInfo, setTestStatusInfo] = useState<string>('');
    const [menu1Open, setMenu1Open]           = useState<boolean>(false);
    const [menu2Open, setMenu2Open]           = useState<boolean>(false);

    // Context
    const {user} = useContext(GeneralContext);

    function checkFolder(id: number, folder: string) {
        setFolderStatus('waiting');

        api.post('documents/checkFolder', { id, folder }, {
            headers: { Authorization: user.token }
        }).then(resp => {
            if (resp.data['.tag'] && resp.data['.tag']==='folder') {
                setFolderStatus('success');
            } else if (resp.data.error) {
                setFolderStatus('error');
            }

            setTimeout(() => {
                setFolderStatus('');
            }, 5000);
        });
    }

    function testCCEE (agent: number, meter: string) {
        setTestStatus('waiting');
        let date = new Date();

        api.post(`clients/testCCEE/${agent}/${meter}/${date.getFullYear()}-${date.getMonth()+1}`, {}, {
            headers: { Authorization: user.token }
        }).then(resp => {
            if (resp.data.code===200) setTestStatus('success');
            else setTestStatus('error');
            setTestStatusInfo(resp.data.message);

            setTimeout(() => {
                setTestStatus('');
                setTestStatusInfo('');
            }, 5000);
        });
    }

    function changeMenu (type: string) {
        if (type==='Menu1') {
            setMenu1Open(!menu1Open)
            setMenu2Open(false)
        } else if (type==='Menu2') {
            setMenu1Open(false)
            setMenu2Open(!menu2Open)
        }
    }

    return (
        <div className="unitsBox">
            <div className="unitsData">
                <div className="infos">
                    <h4>{ props.client ? props.client.name : 'Unidade Individual' }</h4>
                    <h3>{ props.name }</h3>
                </div>

                <div className="state">
                    { props.state }
                </div>

                <button
                    type="button"
                    className={`testCCEEClient ${ testStatus!=='' ? testStatus : '' }`}
                    onClick={() => testCCEE(props.agent, props.meter)}
                    title={`Teste CCEE ${ testStatusInfo!=='' ? '- ' + testStatusInfo : '' }`}
                    data-for="tooltip_units_hover"
                    data-tip="Testar Conexão CCEE"
                    data-place="left"
                >
                    <FiSettings size={20} />
                </button>
            </div>

            <div className="buttons">
                {
                    menu1Open && <div className='subMenu'>
                        <button
                            type="button"
                            className="energyClient"
                            onClick={() => props.energies ? props.energies() : ''}
                            title="Tipo de Energia"
                            data-for="tooltip_units_submenu"
                            data-tip="Tipo de Energia"
                            data-place="bottom"
                        >
                            <FiZap size={16} />
                        </button>

                        <button
                            type="button"
                            className="pricesClient"
                            onClick={() => props.prices ? props.prices() : ''}
                            title="Preço"
                            data-for="tooltip_units_submenu"
                            data-tip="Preço"
                            data-place="bottom"
                        >
                            <FiDollarSign size={16} />
                        </button>

                        <button
                            type="button"
                            className="lostEnergyClient"
                            onClick={() => props.lostEnergy ? props.lostEnergy() : ''}
                            title="Perda de Energia"
                            data-for="tooltip_units_submenu"
                            data-tip="Perda de Energia"
                            data-place="bottom"
                        >
                            <FiZapOff size={16} />
                        </button>

                        <ReactTooltip
                            id="tooltip_units_submenu"
                            place="top"
                            effect="solid"
                            type="info"
                        />
                    </div>
                }

                {
                    menu2Open && <div className='subMenu'>
                        <button
                            type="button"
                            className="icmsClient"
                            onClick={() => props.icms ? props.icms() : ''}
                            title="ICMS"
                            data-for="tooltip_units_submenu"
                            data-tip="ICMS"
                            data-place="bottom"
                        >
                            <FiTag size={16} />
                        </button>

                        <button
                            type="button"
                            className="othersClient"
                            onClick={() => props.others ? props.others() : ''}
                            title="Outros Valores"
                            data-for="tooltip_units_submenu"
                            data-tip="Outros Valores"
                            data-place="bottom"
                        >
                            <FiFilePlus size={16} />
                        </button>

                        <button
                            type="button"
                            className="othersNotesClient"
                            onClick={() => props.othersNotes ? props.othersNotes() : ''}
                            title="Notas de Outros Valores"
                            data-for="tooltip_units_submenu"
                            data-tip="Notas de Outros Valores"
                            data-place="bottom"
                        >
                            <FiBookOpen size={16} />
                        </button>

                        <button
                            type="button"
                            className="valueTeACLClient"
                            onClick={() => props.teACL ? props.teACL() : ''}
                            title="TE ACL"
                            data-for="tooltip_units_submenu"
                            data-tip="TE ACL"
                            data-place="bottom"
                        >
                            <FiZap size={16} />
                        </button>

                        <ReactTooltip
                            id="tooltip_units_submenu"
                            place="top"
                            effect="solid"
                            type="info"
                        />
                    </div>
                }

                <button
                    type="button"
                    className={`folderUser ${folderStatus}`}
                    onClick={() => checkFolder(props.id, props.folder)}
                    title="Verificar Pasta de Documentos"
                    data-for="tooltip_folder_hover"
                    data-tip="Verificar Pasta de Documentos"
                >
                    <FiFolder size={20} />
                </button>

                <button
                    type="button"
                    className="editClient"
                    onClick={() => props.edit ? props.edit() : ''}
                    title="Editar"
                    data-for="tooltip_units_hover"
                    data-tip="Editar"
                    data-place="top"
                >
                    <FiEdit2 size={16} />
                </button>

                <button
                    type="button"
                    className={`editClient${menu1Open && ' active'}`}
                    onClick={() => changeMenu('Menu1')}
                >
                    <FiMenu size={16} />
                </button>

                <button
                    type="button"
                    className={`editClient${menu2Open && ' active'}`}
                    onClick={() => changeMenu('Menu2')}
                >
                    <FiMenu size={16} />
                </button>

                <button
                    type="button"
                    className="deleteClient"
                    onClick={() => props.delete ? props.delete() : ''}
                    title="Excluir"
                    data-for="tooltip_units_hover"
                    data-tip="Excluir"
                    data-place="top"
                >
                    <FiTrash2 size={16} />
                </button>
            </div>

            <ReactTooltip
                id="tooltip_units_hover"
                place="top"
                effect="solid"
                type="info"
            />

            <ReactTooltip
                id="tooltip_folder_hover"
                place="right"
                effect="solid"
                type="info"
            />
        </div>
    );
}

export default Clients;