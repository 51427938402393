import React, { useState, useContext, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiLoader, FiPlus, FiSave, FiZapOff } from 'react-icons/fi';
import { FaSearch, FaTimes } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';
import { UnitsContext } from '~/contexts/forms/units';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import UnitsBox from '~/components/Boxs/AdminUnits';
import CurrencyInput from '~/components/Modules/CurrencyMask';

// Forms
import FormUnit from './Form/Unit';
import FormEnergies from './Form/Energies';
import FormPrices from './Form/Prices';
import FormOthersValues from './Form/OthersValues';
import FormOthersNotes from './Form/OthersNotes';
import FormICMS from './Form/ICMS';
import FormTeAcl from './Form/TeACL';
import FormLostEnergy from './Form/LostEnergy';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';

const AdminUnits: React.FC<any> = () => {
    const navigate                  = useNavigate();
    const [ready, setReady]         = useState<Boolean>();
    const [units, setUnits]         = useState<any>([]);
    const [unitsList, setUnitsList] = useState<any>([]);
    const [groups, setGroups]       = useState<any>([]);
    const [providers, setProviders] = useState<any>([]);
    const [search, setSearch]       = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;

    // Local Editions
    const [lostEnergy, setLostEnergy]         = useState<any>(null);
    const [lostEnergyEdit, setLostEnergyEdit] = useState<Boolean>(false);

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);
    const Units  = useContext(UnitsContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let clientsFilter = unitsList.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.client_name && el.client_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.state && el.state.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.city && el.city.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setUnits(clientsFilter);
    }

    function openModal(modal: any, data?: any) {
        let dataUnit: any = null;
        let newUpdate     = false;

        Modal.setModalOpen(false);
        Modal.setModalClass('munits');

        switch (modal) {
            case 'addClient':
                Modal.setModalTitle('Adicionando Unidade Consumidora')

                dataUnit = {
                    id            : 0,
                    client_id     : 0,
                    client_type   : 'serena',
                    init_contract : '',
                    name          : '',
                    cnpj          : '',
                    cnpj_matriz   : null,
                    agent         : 0,
                    meter         : '',
                    city          : '',
                    state         : '',
                    address       : '',
                    cost_reference: 0,
                    active        : true,
                    provider      : {
                        provider_id   : 0,
                        type          : 'conv',
                        modality      : 'Azul',
                        power         : 'A3',
                        demand_tip    : 0,
                        demand_off_tip: 0,
                        icms          : 0,
                        icms_diff     : 0
                    }
                }

                newUpdate = true;
                break;

            case 'editClient':
                Modal.setModalTitle('Editando Unidade Consumidora')

                if (data) {
                    dataUnit = {
                        id            : data.id,
                        client_id     : data.client ? data.client.id : 0,
                        client_type   : data.client_type ? data.client_type : 'serena',
                        init_contract : data.init_contract ? treatDateContract(data.init_contract) : '',
                        name          : data.name,
                        cnpj          : data.cnpj,
                        cnpj_matriz   : data.cnpj_matriz || null,
                        agent         : data.agent,
                        profile       : data.profile,
                        meter         : data.meter,
                        proinfa_code  : data.proinfa_code,
                        city          : data.city,
                        state         : data.state,
                        address       : data.address,
                        cost_reference: data.cost_reference,
                        active        : data.active,
                        provider      : {
                            provider_id   : data.provider.provider.id,
                            type          : data.provider.type,
                            modality      : data.provider.modality,
                            power         : data.provider.power,
                            demand_tip    : data.provider.demand_tip,
                            demand_off_tip: data.provider.demand_off_tip,
                            icms          : data.provider.icms,
                            icms_diff     : data.provider.icms_diff
                        }
                    }
                }

                newUpdate = true;
                break;

            case 'deleteClient':
                Modal.setModalClass('delete')
                Modal.setModalTitle('Excluindo Unidade Consumidora')
                Modal.setModalBody(<>
                    <div>Deseja realmente excluir a unidade consumidora <i>"{ data?.name }"?</i></div>
                    <div className="alert">* Tenha cuidado, esse processo é irreversível!</div>

                    <footer>
                        <button id="btnDelete" type="button" onClick={() => Units.remove(data.id, Modal, setReady)}>Excluir</button>
                        <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                    </footer>
                </>)
                Modal.setModalOpen(true)
                break;

            case 'energiesClient':
                Modal.setModalClass('menergies')
                Modal.setModalTitle('Editando Tipo de Energia')
                Modal.setModalBody(<FormEnergies unit={data.id} energies={data.energies} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            case 'pricesClient':
                Modal.setModalClass('mprices')
                Modal.setModalTitle('Editando Preços')
                Modal.setModalBody(<FormPrices unit={data.id} prices={data.prices} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            case 'othersValues':
                Modal.setModalClass('movalues')
                Modal.setModalTitle('Editando Outros Valores')
                Modal.setModalBody(<FormOthersValues unit={data.id} others={data.others} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            case 'othersNotes':
                Modal.setModalClass('monotes')
                Modal.setModalTitle('Editando Notas de Outros Valores')
                Modal.setModalBody(<FormOthersNotes unit={data.id} notes={data.others_notes} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            case 'icms':
                Modal.setModalClass('micms')
                Modal.setModalTitle('Editando observação sobre ICMS')
                Modal.setModalBody(<FormICMS unit={data.id} icms={data.icms} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            case 'lostEnergy':
                Modal.setModalClass('mlostenergy')
                Modal.setModalTitle('Editando Perda de Energia')
                Modal.setModalBody(<FormLostEnergy unit={data.id} lostEnergy={data.lost_energy} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            case 'teAcl':
                Modal.setModalClass('mteAcl')
                Modal.setModalTitle('Adicionando valore da TE ACL')
                Modal.setModalBody(<FormTeAcl unit={data.id} teAcl={[]} setReady={setReady} />)
                Modal.setModalOpen(true)
                break;

            default:
                Modal.setModalClass(null)
                Modal.setModalTitle('')
                Modal.setModalBody(<></>)
                break;
        }

        if (newUpdate) {
            Modal.setModalBody(<FormUnit unit={dataUnit} groups={groups} providers={providers} setReady={setReady} />);
            Modal.setModalOpen(true)
        }
    }

    function onChangeFloat(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, originalValue: any) {
        let {id, name} = event.target;
        let data: any  = {[name]: originalValue};

        // Check if is a object
        if (name.indexOf('.')>=0) {
            let nBase    = name.split('.')
            name = nBase[0]

            if (nBase.length===2) data = {[nBase[0]]: { [nBase[1]]: originalValue}}
            else if (nBase.length===3) data = {[nBase[0]]: { [nBase[1]]: { [nBase[2]]: originalValue}}}
            else if (nBase.length===4) data = {[nBase[0]]: { [nBase[1]]: { [nBase[2]]: { [nBase[3]]: originalValue}}}}
        }

        if (id==='lostEnergy') setLostEnergy({ ...lostEnergy, [name]: (!data[name] || (data[name] && Number(data[name])===0) ? null : data[name]) });
    }

    function treatDateContract (date: string) {
        let contractDate = new Date(date);
        let month        = contractDate.getUTCMonth()+1;
        let year         = contractDate.getUTCFullYear();

        return (month>0 ? '0' : '') + month + '/' + year;
    }

    const handleNextPage = () => {
        if (currentPage < Math.ceil(units.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUnits = units.slice(indexOfFirstItem, indexOfLastItem); 

    useEffect(() => {
        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else if(!ready) api.get('clients/list', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setUnits(resp.data.clients);
            setUnitsList(resp.data.clients);
            setGroups(resp.data.groups);
            setProviders(resp.data.providers);
            setLostEnergy(resp.data.lostEnergy);
            setReady(true);
        });
    }, [user, ready]);

    return <Admin pageTitle="Gerenciamento de Unidades Consumidoras - Clientes Energizou" title="Gerenciamento"  subtitle="Unidades Consumidoras dos Clientes" classMain='adm_units'>
        {
            ready ? <>
                <div className="header">
                    <div className="buttons">
                        <button
                            type="button"
                            className="addClient"
                            onClick={() => openModal('addClient')}
                            data-for="tooltip_units_page"
                            data-tip="Adicionar Unidade"
                        >
                            <FiPlus size="16" />
                        </button>

                        <div className="lostEnergyClient">
                            <span data-for="tooltip_units_page" data-tip="Perda de Energia">
                                <span data-tip="React-tooltip" data-for="lostEnergyBox" data-event='click focus'>
                                    <FiZapOff size={16} />
                                </span>
                            </span>

                            <ReactTooltip id="lostEnergyBox" place="bottom" type="light" effect="solid" globalEventOff='click' clickable={true}>
                                <CurrencyInput
                                    id="lostEnergy" name="value" placeholder="0,00"
                                    onChangeValue={onChangeFloat}
                                    value={lostEnergy.value ? lostEnergy.value : ''}
                                    disabled={lostEnergyEdit ? true : false}
                                    hideSymbol
                                />

                                <div className="btns">
                                    {
                                        !lostEnergyEdit ? (
                                            <button className="save" onClick={() => Units.saveGlobalLostEnergy(lostEnergy, setLostEnergy, setLostEnergyEdit)}>
                                                <FiSave size={16} />
                                            </button>
                                        ) : (
                                            <button className="loading">
                                                <FiLoader size={16} />
                                            </button>
                                        )
                                    }
                                </div>
                            </ReactTooltip>
                        </div>
                    </div>

                    <div className='searchBox'>
                        <input
                            name="search" id="search"
                            type="text" autoComplete='false'
                            placeholder='Nome da unidade, Grupo ou Estado...'
                            value={search} onChange={onChangeSearch}
                            disabled={ready ? false : true}
                        />

                        <div className={`btn${search !=='' ? ' lnk' : ''}`} onClick={() => {
                            if (search !=='') {
                                setUnits(unitsList)
                                setSearch('')
                            }
                        }}>
                            { search !=='' ? <FaTimes /> : <FaSearch /> }
                        </div>
                    </div>
                </div>

                <div className="units">
                    {
                        currentUnits.map((client: any) => (
                            <UnitsBox
                                key         = {`unit_${client.id}`}
                                {...client}
                                edit        = {() => openModal('editClient', client)}
                                energies    = {() => openModal('energiesClient', client)}
                                prices      = {() => openModal('pricesClient', client)}
                                others      = {() => openModal('othersValues', client)}
                                teACL       = {() => openModal('teAcl', client)}
                                othersNotes = {() => openModal('othersNotes', client)}
                                icms        = {() => openModal('icms', client)}
                                lostEnergy  = {() => openModal('lostEnergy', client)}
                                delete      = {() => openModal('deleteClient', client)}
                            />
                        ))
                    }
                </div>

                <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}><MdOutlineNavigateBefore /></button>
                        <span>Página {currentPage} de {Math.ceil(units.length / itemsPerPage)}</span>
                        <button onClick={handleNextPage} disabled={currentPage === Math.ceil(units.length / itemsPerPage)}><MdOutlineNavigateNext /></button>
                </div>

                <ReactTooltip
                    id="tooltip_units_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
        }
    </Admin>
}

export default AdminUnits;